import React, { useEffect, useState } from 'react'
import { getResource, postResource } from '../../service/api';
import { Bar, Line } from 'react-chartjs-2';
import { Table } from 'reactstrap';



export default function ContentModuleStatisticViewer({ indicateur, type_campagne, type_production, view }) {

    const [request, setRequest] = useState({})
    const [culture, setCulture] = useState([indicateur.culture])
    const [campagnes, setCampagnes] = useState([])
    const [datas, setDatas] = useState([])
    const [dataSource, setDataSource] = useState(null)


    const options = {
        scales: {
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                        callback: function (value, index, values) {
                            return `${value} t`;
                        }
                    },
                },
            ],

        },
    }



    useEffect(() => {
        getResource(`campagnes?type_campagne=${type_campagne}&type_production=${type_production}`).then(res => {
            setRequest({ ...request, campagne: res.data.data.map(d => d.id), culture })
            setCampagnes(res.data.data.map(d => `${d.yearbegin}-${d.yearend}`))
        })
    }, [])
    useEffect(() => {
        if (request) {
            postResource('cultureDatas', request).then(res => {
                setDatas(res.data)
            })
        }
    }, [request])

    useEffect(() => {
        if (datas) {

            const cultures = Object.keys(datas)
            const dataSource = {
                labels: campagnes,
                datasets: cultures.map(c => {
                    var randomColor = Math.floor(Math.random() * 16777215).toString(16)
                    return {
                        label: c,
                        data: datas[c].map(d => d.production),
                        fill: false,
                        backgroundColor: `#20c997`,
                        borderColor: `#17a2b8`,

                    }
                })
            }

            setDataSource(dataSource)

        }
    }, [datas])

    return (
        <>
            {view == "line" && <Line data={dataSource} options={options} />}
            {view == "bar" && <Bar data={dataSource} options={options} />}
            {view == "map" && <h5>En cours ...</h5>}
            {view == "table" && <Table className="table table-bordered table-striped">
                <tr>
                    <th>Campagne</th>
                    <th>Culture</th>
                    <th>Superficie (Ha)</th>
                    <th>Rendement (Kg/Ha)</th>
                    <th>Production (Tonne)</th>
                </tr>

                    {
                        Object.keys(datas).map(cultures=>datas[cultures].map(c=>(               
                             <tr>
                            <td className="text-bold">{c.years}</td>
                            <td>{c.culture}</td>
                            <td>{c.superficie}</td>
                            <td>{parseInt(c.rendement)}</td>
                            <td>{parseInt(c.production)}</td>
                        </tr>
        )))
                    }
            </Table>}
        </>
    )
}
