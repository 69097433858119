import React, { useEffect, useState } from 'react'
import Ariane from '../components/Common/Ariane';
import ContentModulePhotosItems from '../components/Module/ContentModulePhotosItems';

export default function MediathequeItems() {

    return (
        <>
            <Ariane title="Médiathèque"/>
            <ContentModulePhotosItems />
        </>
    )
}
