import React, { useEffect, useState } from 'react';
import { getResource } from "../../service/api";
import { Alert } from 'react-bootstrap';

const directory_doc = process.env.REACT_APP_UPLOAD_DIRECTORY;

export default function ContentModuleReforme({ module }) {

    const [reformes, setReformes] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [numberPage, setNumberPage] = useState([])
    const [query, setQuery] = useState("");


    const HandleSubmit = async (e) => {
        e.preventDefault();

        if (query) {

            await getResource(`documentations?title=${query}&module_id=${module.id}`).then((res) => {
                setReformes(res.data.data);
            })

        } else {

            alert('veuillez renseigner ce champ');

        }

    }

    const getQuery = (e) => {
        setQuery(e.target.value);
    }

    const reformeData = async () => {
        await getResource(`documentations?module_id=${module.id}&page=${currentPage}&public=1`).then(res => {
            setReformes(res.data.data)
            let array_pages = Math.ceil(parseInt(res.data.total) / parseInt(res.data.per_page))
            array_pages = Array.from(Array(array_pages).keys())
            setNumberPage(array_pages);
        })
    }

    useEffect(() => {
        reformeData();

        if (query === "") {
            reformeData();
        }
    }, [currentPage, query])


    return (
        <div id="simple-page" className="pt-90 pb-120 bg-white">
            <div className="container">
                <div className="row">
                    <div className="col-md-6 offset-md-3 pb-40">
                        <div className="input-group mb-3 mt-4">
                            <input onChange={getQuery} type="text" className="form-control newsletter-input" placeholder={`${typeof query !== 'undefined' && query.length > 0 ? query : "Recherche un document"}`}
                                aria-label="Recipient's username" aria-describedby="basic-addon2" />
                            <div className="input-group-append">
                                <span type="" onClick={HandleSubmit} className="input-group-text site-secondary-bgcolor" id="basic-addon2"><i
                                    className="fa fa-search"></i></span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row mt-4 pb-4 border-bottom">
                    {typeof reformes !== 'undefined' && reformes.length > 0 ? reformes.map((r, index) => (
                        <div className="col-md-4 mt-4" key={index}>
                            <div className="card">
                                <div className="d-flex justify-content-center align-items-center reforme-header bg-secondary">
                                    <div className="p-2 font-weight-bold">{r.title.substr(0, 50)}</div>
                                    {/* <div className="p-2 date-zone bg-success"><small>&nbsp;</small></div> */}
                                </div>
                                <div className="card-body">
                                    <p className="text-justify mb-3">
                                        {r.description && r.description.substr(0, 200)}
                                    </p>
                                    <div className="d-flex ">
                                        <div><a href={r.fichier ? `${directory_doc}/${r.fichier.filename}` : '#'} target="_blank" className="download" ><button className="btn btn-outline-success btn-sm text-uppercase download"><i
                                            className="fa fa-download"></i> Télécharger</button></a></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )) : <div className=" center text-warning" > <b>Désolé(e), Aucune donnée disponible !</b></div>}
                </div>


                <div className="row">
                    <div className="col-lg-12">
                        <nav className="courses-pagination mt-50 pb-3">
                            <ul className="pagination justify-content-center">
                                <li className="page-item cursor">
                                    <a onClick={() => currentPage > 1 && setCurrentPage(currentPage - 1)} aria-label="Précédent" >
                                        <i className="fa fa-angle-left"></i>
                                    </a>
                                </li>
                                {
                                    numberPage.map((n) => (
                                        <li key={`page${n + 1}`} className="page-item cursor"><a className={currentPage == (n + 1) && 'active'} onClick={() => setCurrentPage(n + 1)}>{n + 1}</a></li>
                                    ))
                                }
                                <li className="page-item cursor">
                                    <a onClick={() => currentPage < numberPage.length && setCurrentPage(currentPage + 1)} aria-label="Suivant">
                                        <i className="fa fa-angle-right"></i>
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    )
}
