import React, { useEffect, useState } from 'react'
import { getResource } from '../../service/api'
import {Alert} from 'react-bootstrap';


export default function ContentModuleTeam({ module }) {

    const [partners, setPartners] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [numberPage, setNumberPage] = useState([])

    useEffect(() => {
        getResource(`partners?modules_partners_id=${module.id}&page=${currentPage}&public=1`).then(res => {
            setPartners(res.data.data)
            let array_pages = Math.ceil(parseInt(res.data.total) / parseInt(res.data.per_page))
            array_pages = Array.from(Array(array_pages).keys())
            setNumberPage(array_pages);
        })
    }, [currentPage])


    return (
        <div>
            {typeof partners !== 'undefined' && partners.length > 0 ?
            <section id="event-page" className="pt-10 pb-20 gray-bg">
                <div className="container">
                    <div className="row">
                        {
                             partners.map(p => (
                                <div className="col-lg-4" key={p.id}>
                                    <div className="single-event-list-2 mt-30">

                                        {p.fichier && <div className="event-thum">
                                            <img src={p.fichier.filename} alt={p.name} />
                                        </div>}
                                        <div className="event-cont">
                                            <a href="" className="text-center">
                                                <h4>{p.name}</h4>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }



                    </div>
                    <div className="row">
                    <div className="col-lg-12">
                        <nav className="courses-pagination mt-50 pb-3">
                            <ul className="pagination justify-content-center">
                                <li className="page-item">
                                    <a onClick={() => currentPage > 1 && setCurrentPage(currentPage - 1)} aria-label="Précédent" >
                                        <i className="fa fa-angle-left"></i>
                                    </a>
                                </li>
                                {
                                    numberPage.map((n) => (
                                        <li key={`page${n + 1}`} className="page-item"><a className={currentPage == (n + 1) && 'active'} onClick={() => setCurrentPage(n + 1)}>{n + 1}</a></li>
                                    ))
                                }
                                <li className="page-item">
                                    <a onClick={() => currentPage < numberPage.length && setCurrentPage(currentPage + 1)} aria-label="Suivant">
                                        <i className="fa fa-angle-right"></i>
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
                </div>
            </section> 

            : <Alert variant="secondary"><h3>Pas de Partenaire disponible</h3></Alert>
            
        }

        </div>
    )
}
