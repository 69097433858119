import React from 'react'

function SlideToUp() {
    return (
        <div>
            <a href="#" className="back-to-top" style={{display: 'inline-block'}}><i className="fa fa-angle-up"></i></a>
        </div>
    )
}

export default SlideToUp
