import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import Ariane from '../components/Common/Ariane';
import ContentModule from '../components/Module/ContentModule';
import { getResource } from '../service/api';
import Loader from '../components/Common/Loader'

export default function Module() {

    const { slug } = useParams();
    const [currentmodule, setCurrentmodule] = useState(null);

    useEffect(() => {
        getResource(`modules?name=${slug}`).then(res => {
            console.log(res.data.data)
            if (res.data.data.length > 0) {
                setCurrentmodule(res.data.data[0]);
            }

        })

    }, []);

    return (
        <>
            {!currentmodule && <Loader />}
            {currentmodule && <Ariane title={currentmodule.name} />}
            {currentmodule && <ContentModule module={currentmodule} />}
        </>
    )
}
