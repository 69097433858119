import React, { useEffect, useState } from 'react'
import { getResource } from '../../service/api'
import { postResource } from '../../service/api'
import {
    Card,
    Form,
    Button,
    Alert
} from 'react-bootstrap';

import { useParams } from 'react-router-dom'


export default function ContentModuleContact({ module }) {

    const [contacts, setContacts] = useState([])
    const [data, setData] = useState([])
    const [contactEmail, setContactEmail] = useState([])
    const [alert, setAlert] = useState(null);
    const [button, setButton] = useState(<i className="fa fa-paper-plane"></i>);
    const [changeStyle, setChangeStyle] = useState({ color: '#fff', backgroundColor: '#4AA340' })
    const [isDisabled, setDisabled] = useState('');
    const [email, setEmail] = useState("");

    const { subject } = useParams();

    useEffect(() => {
        getResource(`contacts?module_id=${module.id}&public=1`).then(res => {
            setContacts(res.data.data)
        })

        getResource(`subjects?title=${subject}`).then(res => {
            setContactEmail(res.data.data[0])
        })
    }, [])

    const onSubmit = (event) => {
        event.preventDefault();

        const mail = subject ? contactEmail.email : null;
        const buttonContent = <i className="fa fa-spinner fa-pulse fa-2x fa-fw"></i>;
        setDisabled('disabled')

        const contact = (endpoint, datas) => {

            if (/^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]{2,}[.][a-z]{2,3}$/.test(email)) {
                postResource(endpoint, datas).then(() => {
                    setChangeStyle({ color: '#fff', backgroundColor: '#4AA340' })
                    setAlert({ success: true, message: 'Votre message a été prise en compte' })
                    setButton(<i className="fa fa-paper-plane"></i>)
                    resetForm()
                    setDisabled('')

                }, () => {
                    setAlert({ success: false, message: 'Erreur lors de l\'envoie' });
                    setButton(<i className="fa fa-paper-plane"></i>)
                    setChangeStyle({ color: 'black' })
                    setDisabled('')
                }).finally(() => {
                    setTimeout(() => {
                        setAlert(null);
                        //   window.location.reload()
                    }, 10000);

                });
            } else {
                setDisabled('')
                setAlert({ success: false, message: 'Votre addresse email est invalide' })
                setButton(<i className="fa fa-paper-plane"></i>)
                setChangeStyle({ color: 'black' })
                setTimeout(() => {
                    setAlert(null);
                    //   window.location.reload()
                }, 10000);
            }



        }

        if (mail) {
            setButton(buttonContent)
            contact('alertMail', { ...data, emails: mail });

        } else {
            setButton(buttonContent)
            contact('contactMail', data);
        }
    }

    const resetForm = () => {
        document.getElementById("contact-form").reset();
    }

    const getEmail = (e) => {
        setEmail(e.target.value);
    }




    return (
        <section id="contact-page" className="pt-30 pb-30 white-bg">
            <div className="container">

                <div className="row">
                    <div className="col-lg-7">
                        <div className="contact-from mt-30">
                            <div className="section-title">
                                <h5>{subject ? subject : 'Contactez-nous'}</h5>
                            </div>
                            <div className="main-form pt-45">
                                <form id="contact-form" onSubmit={onSubmit} method="post" data-toggle="validator">
                                    {
                                        alert && <Alert style={changeStyle} variant={alert.success ? 'success' : 'danger'} >
                                            {alert.message}
                                        </Alert>
                                    }
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="single-form form-group">
                                                <input onInput={(e) => setData({ ...data, name: e.target.value })} name="name" type="text" placeholder="Votre nom"
                                                    data-error="Ceci est un champ obligatoire." required="required" />
                                                <div className="help-block with-errors"></div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="single-form form-group">
                                                <input onInput={(e) => setData(subject ? { ...data, email: e.target.value, sujet: subject } : { ...data, email: e.target.value, sujet: '' })} onChange={getEmail} name="email" type="email" placeholder="Email"
                                                    data-error="Ceci est un champ obligatoire." required="required" />
                                                <div className="help-block with-errors"></div>
                                            </div>
                                        </div>
                                        {subject ?

                                            <div className="col-md-6">
                                                <div className="single-form form-group">
                                                    <input disabled className="color-green" name="sujet" value={subject.substr(0, 30)} type="text" placeholder="Sujet"
                                                        data-error="Ceci est un champ obligatoire." required="required" />
                                                    <div className="help-block with-errors"></div>
                                                </div>
                                            </div>

                                            :
                                            <div className="col-md-12">
                                                <div className="single-form form-group">
                                                    <input onInput={(e) => setData({ ...data, telephone: e.target.value })} name="phone" type="text" placeholder="Telephone"
                                                        data-error="Ceci est un champ obligatoire." required="required" />
                                                    <div class="help-block with-errors"></div>
                                                </div>
                                            </div>

                                        }

                                        {subject ?
                                            <div className="col-md-6">
                                                <div className="single-form form-group">
                                                    <input onInput={(e) => setData({ ...data, telephone: e.target.value })} name="phone" type="text" placeholder="Telephone"
                                                        data-error="Ceci est un champ obligatoire." required="required" />
                                                    <div class="help-block with-errors"></div>
                                                </div>
                                            </div>
                                            : ''
                                        }
                                        <div class="col-md-12">
                                            <div class="single-form form-group">
                                                <textarea onInput={(e) => setData({ ...data, message: e.target.value })} name="message" placeholder="Message"
                                                    data-error="Ceci est un champ obligatoire."
                                                    required="required"></textarea>
                                                <div class="help-block with-errors"></div>
                                            </div>
                                        </div>
                                        <p class="form-message"></p>
                                        <div class="col-md-12">
                                            <div class="single-form">
                                                {isDisabled == 'disabled' ? <button disabled type="submit" className="main-btn">Encours {button}</button> : <button type="submit" className="main-btn">Envoyer {button}</button>}
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-5">
                        <div class="contact-address mt-30">
                            {contacts.map(c => (

                                <div class="single-address mb-30 border-bottom pb-15" key={c.id}>
                                    <div class="cont  w-100">
                                        {c.name && <h6 className="text-uppercase mb-10"> {c.name}</h6>}
                                        {c.phone && <p> <i className="fa fa-phone mr-10"></i> {c.phone}</p>}
                                        {c.email && <p> <i className="fa fa-envelope mr-10"></i>{c.email}</p>}
                                        {c.website && <a href={c.website} target="_blank"> <i className="fa fa-link mr-10"></i>{c.website}</a>}
                                    </div>
                                </div>

                            ))}

                        </div>
                        <div class="map mt-30">
                            <div id="contact-map"></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>


    )
}
