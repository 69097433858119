import React, { useEffect, useState } from 'react'
import Ariane from '../components/Common/Ariane';
import ContentNews from '../components/News/ContentNews';
import { getResource } from '../service/api';
import Loader from '../components/Common/Loader'
import { useParams } from 'react-router';

export default function News() {

    const { slug } = useParams();
    const [currentnews, setCurrentNews] = useState(null);

    useEffect(() => {

        getResource(`news?permalink=${slug}`).then(res => {
            if (res.data.data.length > 0) {
                setCurrentNews(res.data.data[0]);
            }
        })
    }, []);

    return (
        <>
            {!currentnews && <Loader />}
            {currentnews && <Ariane title={currentnews.title} />}
            {currentnews && <ContentNews news={currentnews} />}
        </>
    )
}
