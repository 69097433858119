import React, {useEffect, useState} from 'react'
import SearchViewContent from '../components/Common/SearchViewContent'
import { useParams } from 'react-router';
import {postResource} from '../service/api';
import Ariane from '../components/Common/Ariane';

export default function SearchView() {

    const {slug} = useParams();
    const [searchdata, setSearchdata] = useState([]);
    const [status, setStatus] = useState(false);

    useEffect(() => {

        postResource('search', {query : slug}).then(res => {    
                setSearchdata(res.data); 
                if(res.data.news.length == 0 && res.data.documentation.length  == 0  && res.data.page.length  == 0 && res.data.partner.length == 0 )
                {
                    setStatus(true);
                }

        })

    }, []);

    const links = [
        {name:"Accueil", target:"/recherche"},
        {name:"Recherche",active:true},
    ]


    return (
        <div>
            <Ariane links={links} title="Recherche"/>
            {status && status == true && <div className="row mt-4 mx-5 mb-4 border-bottom center p-4 text-warning" > <b>Aucune donnée ne correspond à votre recherche !</b></div>}
            <SearchViewContent slug={slug} data={searchdata}/>
        </div>
    )
}
