import React, { useState } from 'react';
import moment from 'moment'

const directory_doc = process.env.REACT_APP_UPLOAD_DIRECTORY;

const ContentNews = ({ news }) => {
    const [afficherImage, setAfficherImage] = useState(<div className="thum"></div>);
    const [onceOnly, setOnceOnly] = useState(true)

    if (news.fichier != null) {
        if (onceOnly == true) {
            setAfficherImage(<div className="thum">
                <img src={`${directory_doc}/${news.fichier.filename}`} alt="Blog Details" />
            </div>)
            setOnceOnly(false)
        }
    }

    return (
        <section id="blog-single" className="pt-90 pb-120 gray-bg">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="blog-details mt-30">
                            {afficherImage}
                            <div className="cont">
                                <h3>{news.title}</h3>
                                <ul>
                                    <li><a href="#"><i className="fa fa-calendar"></i>{moment(news.created_at).format('DD-MMM-YYYY')}</a></li>
                                    {/* <li><a href="#"><i className="fa fa-user"></i>{`${news.users.firstname} ${news.users.lastname}`}</a></li> */}
                                    <li><a href="#"><i className="fa fa-tags"></i>{news.modules.name}</a></li>
                                </ul>
                                <p dangerouslySetInnerHTML={{ __html: news && news.content }}></p>
                                {/* <ul className="share">
                            <li className="title">Partage :</li>
                            <li><a href="#"><i className="fa fa-facebook-f"></i></a></li>
                            <li><a href="#"><i className="fa fa-twitter"></i></a></li>
                            <li><a href="#"><i className="fa fa-google-plus"></i></a></li>
                            <li><a href="#"><i className="fa fa-instagram"></i></a></li>
                            <li><a href="#"><i className="fa fa-linkedin"></i></a></li>
                        </ul> */}
                            </div>
                        </div>
                    </div>
                    {/* <div className="col-lg-4">
                <div className="sidebar">
                    <div className="row">
                        <div className="col-lg-12 col-md-6">
                            <div className="sidebar-search mt-30">
                                <form action="#">
                                    <input type="text" placeholder="Search"/>
                                    <button type="button"><i className="fa fa-search"></i></button>
                                </form>
                            </div> 
                            <div className="categories mt-30">
                                <h4>Categories</h4>
                                <ul>
                                    <li><a href="#">Fronted</a></li>
                                    <li><a href="#">Backend</a></li>
                                    <li><a href="#">Photography</a></li>
                                    <li><a href="#">Technology</a></li>
                                    <li><a href="#">GMET</a></li>
                                    <li><a href="#">Language</a></li>
                                    <li><a href="#">Science</a></li>
                                    <li><a href="#">Accounting</a></li>
                                </ul>
                            </div>
                        </div> 
                        <div className="col-lg-12 col-md-6">
                            <div className="sidebar-post mt-30">
                                <h4>Articles similaires</h4>
                                <ul>
                                    <li>
                                        <a href="#">
                                            <div className="single-post">
                                                <div className="thum">
                                                    <img src="dist/images/blog/blog-post/bp-1.jpg" alt="Blog"/>
                                                </div>
                                                <div className="cont">
                                                    <h6>Introduction to languages</h6>
                                                    <span>20 Dec 2018</span>
                                                </div>
                                            </div> 
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <div className="single-post">
                                                <div className="thum">
                                                    <img src="dist/images/blog/blog-post/bp-2.jpg" alt="Blog"/>
                                                </div>
                                                <div className="cont">
                                                    <h6>How to build a game with java</h6>
                                                    <span>10 Dec 2018</span>
                                                </div>
                                            </div> 
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <div className="single-post">
                                                <div className="thum">
                                                    <img src="dist/images/blog/blog-post/bp-1.jpg" alt="Blog"/>
                                                </div>
                                                <div className="cont">
                                                    <h6>Basic accounting from primary</h6>
                                                    <span>07 Dec 2018</span>
                                                </div>
                                            </div>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div> 
                </div> 
            </div> */}
                </div>
            </div>
        </section>

    );
}

export default ContentNews;