import React from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

const Ariane = ({title}) => {

    return (
        <section id="page-banner" className="pt-30 pb-30 bg_cover" data-overlay="8" >
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="page-banner-cont">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="/">Accueil</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">{title}</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

Ariane.propTypes ={
    title: PropTypes.string.isRequired,
}
export default Ariane;