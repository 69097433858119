import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../App';
import _ from 'lodash'
import { useParams, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';

const PUBLIC_URL = process.env.PUBLIC_URL

function usePageViews() {
    let location = useLocation();
    useEffect(() => {
        if (!window.GA_INITIALIZED) {
            ReactGA.initialize('UA-200273952-1')
            window.GA_INITIALIZED = true;
        }
        ReactGA.set({ page: location.pathname });
        ReactGA.pageview(location.pathname);
    }, [location])
}

const Navigation = () => {
    const { appState, setAppState } = useContext(AppContext)
    let { collapsed, defaultdatas: { menus } } = appState
    const [sticky, setSticky] = useState(false)
    // const [menu_parent,setParentMenu]= useState(menu_parent)


    const generateLink = menu => {
        if (menu.page_id) return `/page/${menu.parents ? menu.parents.title : "home"}/${menu.pages.permalink}`

        if (menu.module_id) return `/section/${menu.parents ? menu.parents.title : "home"}/${menu.modules.name}`
        if (menu.display_services_pages) return `/content/${menu.parents ? menu.parents.title : "home"}/services`
        if (menu.target_link) {
            if (menu.target_link.indexOf("http") == 0 || menu.target_link.indexOf("https") == 0)
                return PUBLIC_URL + menu.target_link
            return menu.target_link
        }
        return '/'
    }

    useEffect(() => {
        window.addEventListener('scroll', function (ev) {

            var someDiv = window.document.getElementById('header-part');
            var distanceToTop = someDiv.getBoundingClientRect().top;
            if (distanceToTop < -200) {
                setSticky(true)
            } else {
                setSticky(false)

            }
        });
    }, [])

    const formatChildren = (menus) => {
        const menusMap = menus.filter(m => m.parent_id == null)
        menusMap.map((mp, indexp) => {
            menusMap[indexp]["children"] = []
            menus.map(mn => {
                if (mn.parent_id == mp.id)
                    menusMap[indexp].children.push(mn)
            })
        })

        return menusMap
    }
    menus = formatChildren(menus.data);
    let location = useLocation();

    usePageViews();

    return (
        <header id="header-part">

            {<div className={`navigation ${sticky ? 'sticky' : ''}`}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <nav className="navbar navbar-expand-lg" id="nav">
                                <a className="navbar-brand" href="/">
                                    <img src={`${PUBLIC_URL}/assets/images/logo-4.png`} alt="Logo SGM" className="img-fluid site-logo" />
                                </a>
                                <button onClick={() => { setAppState({ ...appState, collapsed: collapsed == "on" ? "off" : "on" }) }} className={`navbar-toggler ${collapsed == "on" ? 'collapsed' : 'active'}`} type="button" data-toggle="collapse"
                                    data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                                    aria-expanded={collapsed == "on" ? "false" : "true"} aria-label="Toggle navigation">
                                    <span className="icon-bar"></span>
                                    <span className="icon-bar"></span>
                                    <span className="icon-bar"></span>
                                </button>

                                <div className={`collapse navbar-collapse sub-menu-bar collapse ${collapsed == "on" ? '' : 'show'}`} id="navbarSupportedContent">
                                    <ul className="navbar-nav ml-auto">
                                        <li className="nav-item"><a className={location.pathname.split('/')[1] == '' ? 'active' : ''} href="/">ACCUEIL</a></li>
                                        {menus && menus.map((menu) => (
                                            <li className="nav-item" key={`parentmenu${menu.id}`}>
                                                <a className={(menu.title == location.pathname.split('/')[2] || location.pathname.indexOf(menu.title) != -1 || (menu.title == "ACTUALITES" && location.pathname.split('/')[3] == 'Actualités') || (menu.title == "REFORMES" && location.pathname.split('/')[3] == 'Reforme') || (menu.title == "CONTACT" && location.pathname.split('/')[3] == 'Contacts')
                                                    || (menu.title == "PADDSA" && location.pathname.split('/')[1] == 'paddsa')) ? 'active' : ""} href={generateLink(menu)}
                                                >{menu.title}</a>
                                                <ul className="sub-menu">
                                                    {menu.children.map((childrens) => (
                                                        <li key={`submenu${childrens.id}`}><a href={generateLink(childrens)} >{childrens.title}</a></li>
                                                    ))
                                                    }

                                                </ul>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                <div className="right-icon text-right ">
                                    <ul>
                                        <li><span onClick={() => { setAppState({ ...appState, searchBarDisplay: true }) }} id="search"><i className="fa fa-search"></i></span></li>
                                    </ul>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>}
        </header>);
}

export default Navigation;
{/* <img src={`${PUBLIC_URL}/assets/images/logo-4.png`} alt="Logo SGM" className="img-fluid site-logo" /> */ }