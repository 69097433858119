import React, { useEffect, useState } from 'react';
import Ariane from "../Common/Ariane";

const ContentPage = ({page}) => {

    return ( 
<div>
    <section className="pt-15 pb-15">
        <div className="container content-page">
            <div className="row">
                <div className="col-md-12">
                    <div className="privacy-desc privacy-list section-title text-align-justify">
                    <h5 className="mb-3">{page && page.title}</h5>
                        <div dangerouslySetInnerHTML={{__html:page && page.content}}></div>
                    </div>
                </div>
                
            </div>
    
        </div>
    </section>
    </div>

        
     );
}
 
export default ContentPage;