import React, { useContext } from 'react';
import { AppContext } from '../../App';
import Slider from "react-slick";
import '../../assets/css/weblink.css';
import '../../assets/css/style-acces-rapide.css';

const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <div className="col-md-1 arrowPositionLeft survol">
        <span
            {...props}
            className={
                "slick-prev slick-arrow" +
                (currentSlide === 0 ? " slick-disabled" : "")
            }
            style={{ display: "block" }}
            aria-hidden="true"
            aria-disabled={currentSlide === 0 ? true : false}
            type="button"
        >
            <i class="fa fa-angle-double-left fa-2x" aria-hidden="true"></i>
        </span>
    </div>
);

const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (

    <div className="col-md-1 arrowPositionRight survol">
        <span
            {...props}
            className={
                "slick-next slick-arrow" +
                (currentSlide === slideCount - 1 ? " slick-disabled" : "")
            }
            style={{ display: "block" }}

            aria-hidden="true"
            aria-disabled={currentSlide === slideCount - 1 ? true : false}
            type="button"
        >
            <i class="fa fa-angle-double-right fa-2x" aria-hidden="true"></i>

        </span>
    </div>
)

const Links = () => {
    const { appState: { defaultdatas } } = useContext(AppContext)

    const settings = {
        dots: false,
        arrows: true,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        nextArrow: <SlickArrowRight />,
        prevArrow: <SlickArrowLeft />,
        autoplaySpeed: 2000,
        focusOnSelect: true,
        draggable: true,
        accessibility: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            }
        ]
    };

    return (

        <div className="lien-utlies-section pb-2 mb-3 weblink">
            <h5 className="text-center text-white">LIENS UTILES</h5>
            <Slider {...settings} style={{ height: '100px', top: '40px' }}>
                {defaultdatas && defaultdatas.weblinks.map(link => (
                    <div key={link.id} className="rounded border border-light text-white p-2">
                        <a className="text-white liens" href={link.access_link}>
                            <span className="lien-icon"><i className="fa fa-link"></i></span>
                            {link.name}
                        </a>
                    </div>
                ))}
            </Slider>
            <div className="pb-2 mb-3">
                <p>
                    {/* <h5 className="text-white title-acces text-center"></h5> https://sgm.agriculture.gouv.bj/services*/}
                    <div className="row acces-rapide">
                        <div className="col-md-4">
                            <ul>
                                <li><a href="http://sgm.agriculture.gouv.bj/page/SGM/notre-mission" className="liens text-white size-text">Notre mission</a></li>
                                <li><a href="http://sgm.agriculture.gouv.bj/page/SGM/lequipe" className="liens text-white size-text">L'équipe</a></li>
                                <li><a href="https://sgm.agriculture.gouv.bj/services" className="liens text-white size-text">La structure</a></li>
                                <li><a href="http://sgm.agriculture.gouv.bj/section/home/Actualités" className="liens text-white size-text">Actualités</a></li>
                                <li><a href="http://sgm.agriculture.gouv.bj/page/mentions-legales" className="liens text-white size-text">Mentions Légales</a></li>
                            </ul>
                        </div>
                        <div className="col-md-4">
                            <ul>
                                <li><a href="http://sgm.agriculture.gouv.bj/paddsa" className="liens text-white size-text">PADDSA</a></li>
                                <li><a href="http://sgm.agriculture.gouv.bj/section/PROJETS/cartographie" className="liens text-white size-text">Cartographie des projets</a></li>
                                <li><a href="http://sgm.agriculture.gouv.bj/section/PROJETS/Compilation 2019" className="liens text-white size-text">Evènements</a></li>
                                <li><a href="http://sgm.agriculture.gouv.bj/section/home/Reforme" className="liens text-white size-text">Réformes</a></li>
                            </ul>
                        </div>
                        <div className="col-md-4">
                            <ul>
                                <li><a href="http://sgm.agriculture.gouv.bj/section/Documentations/Rapports" className="liens text-white size-text">Rapports</a></li>
                                <li><a href="http://sgm.agriculture.gouv.bj/section/Documentations/Règlementations agricole" className="liens text-white size-text">Réglementations agricoles</a></li>
                                <li><a href="https://sgm.agriculture.gouv.bj/section/DOCUMENTATIONS/Ateliers" className="liens text-white size-text">Rapports d'ateliers</a></li>
                                <li><a href="http://sgm.agriculture.gouv.bj/section/home/Contacts" className="liens text-white size-text">Contacts</a></li>
                            </ul>
                        </div>
                    </div>
                </p>
            </div>
        </div>
    )
}

export default Links
