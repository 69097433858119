import React, { useContext, useEffect, useState } from "react";
import Header from "./layouts/Header";
import Footer from "./layouts/Footer";
import SlideToUp from "./components/Common/SlideToUp";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import NotFound from "./components/Common/NotFound";
import Page from "./pages/Page";
import Home from './pages/Home';
import Module from "./pages/Module";
import { AppContext } from "./App";
import News from "./pages/News";
import Welcome from "./pages/Welcome";
import ServicePage from "./pages/Services";
import Statistique from "./pages/Statistique";
import Mediatheque from "./pages/Mediatheque";
import MediathequeItems from "./pages/MediathequeItems";
import Paddsa from "./pages/Paddsa";
import SearchView from "./pages/SearchView";
import ServiceSgm from "./pages/ServicesSgm";




const AppRouter = (props) => {
  const { appState, setAppState } = useContext(AppContext)

  return (
    <Router>
      {appState.defaultdatas && <Header />}
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/page/:menu_parent/:slug" component={Page} />
        <Route path="/page/:slug" component={Page} />
        <Route path="/news/:slug" component={News} />
        <Route path="/section/:menu_parent/:slug/:subject?" component={Module} />
        <Route path="/welcome" component={Welcome} />
        <Route path="/services" component={ServicePage} />
        <Route path="/statistics/:slug" component={Statistique} />
        <Route path="/mediatheque" component={Mediatheque} />
        <Route path="/mediatheque-items/:slug" component={MediathequeItems} />
        <Route path="/paddsa" component={Paddsa} />
        <Route path="/recherche/:slug" component={SearchView} />
        <Route path="/service/:slug" component={ServiceSgm} />
        <Route path="*" component={NotFound} />
      </Switch>
      <Footer />
      <SlideToUp />
    </Router>

  )
}



export default AppRouter;
