import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../App';
import { useParams } from 'react-router';
import { postResource } from '../../service/api';
import Select from 'react-select';


export default function SearchBar() {

    const { appState, setAppState } = useContext(AppContext);
    const [query, setQuery] = useState([]);
    const { slug } = useParams();
    const [searchdata, setSearchdata] = useState([]);


    const onSubmit = (e) => {

        e.preventDefault();

        if (query.length >= 3) {

            return window.location.assign(`/recherche/${query}`);
        } else {
            alert('saisissez au moins 03 chaine de caractère')
        }

    }

    const getQuery = (e) => {

        setQuery(e.target.value);
        console.log(query)
    }



    return (
        <div className="search-box" style={{ display: appState.searchBarDisplay ? "block" : "none" }}>
            <div className="search-form">
                <div className="closebtn" onClick={() => { setAppState({ ...appState, searchBarDisplay: false }) }} >
                    <span></span>
                    <span></span>
                </div>
                <form onSubmit={onSubmit}>
                    <input type="text" required onChange={getQuery} name="query" placeholder="Recherchez un mot clé" />
                    <button type="submit"><i className="fa fa-search"></i></button>
                </form>

            </div>
        </div>
    )
}
