import React, { useEffect, useState } from 'react';
import Slider from "react-slick";
import { getResource } from '../../service/api';


const directory = process.env.REACT_APP_UPLOAD_DIRECTORY

export default function ContentPaddsa({ page }) {

    const [sliders, setSliders] = useState([]);


    useEffect(() => {

        getResource(`news?public=1&type=paddsa`).then(res => {
            if (res.data.data.length > 0) {
                setSliders(res.data.data);
            }

        })

    }, []);

    var contentPage = page.filter(p => p.type === 1);
    var service = page.filter(p => p.page_service_paddsa === 1);


    const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
        <div className="" >

            <span
                {...props}
                className={
                    "prev slick-arrow" +
                    (currentSlide === 0 ? " slick-disabled" : "")
                }
                style={{ display: "block" }}
                aria-hidden="true"
                aria-disabled={currentSlide === 0 ? true : false}
            >
                <i class="fa fa-angle-left"></i>
            </span>
        </div>
    );

    const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (

        <div className="" >
            <span
                {...props}
                className={
                    "next slick-arrow" +
                    (currentSlide === slideCount - 1 ? " slick-disabled" : "")
                }
                style={{ display: "block" }}

                aria-hidden="true"
                aria-disabled={currentSlide === slideCount - 1 ? true : false}
            >
                <i class="fa fa-angle-right"></i>

            </span>
        </div>
    )
    const SliderSettings = {
        dots: false,
        infinite: true,
        arrows: true,
        speed: 500,
        autoplay:true,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        prevArrow: <SlickArrowLeft />,
        nextArrow: <SlickArrowRight />,
        focusOnSelect: true,
        draggable: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    }

    return (
        <div>
            <section id="slider-part" >
                <Slider {...SliderSettings} className="slider-active">
                    {sliders && sliders.map(s => (
                        <div>
                            <div className="single-slider bg_cover pt-50" style={{ backgroundImage: `url(${directory + '/' + s.fichier.filename})` }} data-overlay="4" >
                                <div className="container">
                                    <div className="row">
                                        <div >
                                            <div className="slider-cont">
                                                <h1 data-animation="fadeInLeft" data-delay="1s">{s.title}</h1>
                                                <p data-animation="fadeInUp" data-delay="1.3s" dangerouslySetInnerHTML={{ __html: s.content }}></p>
                                                <ul>
                                                    <li><a data-animation="fadeInUp" data-delay="1.6s"
                                                        className="main-btn" href={`/news/${s.permalink}`}>Lire plus</a>
                                                    </li>

                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>
            </section>

            <div id="simple-page" className="pt-30 pb-50 bg-light">
                <div className="container">
                    <div className="row">
                        {contentPage &&
                            <div className="col-md-7 section-title content-page">
                                <h5 className="mb-3">{contentPage[0].title}</h5>
                                <div className="text-align-justify" dangerouslySetInnerHTML={{ __html: contentPage[0].content.substr(0, 2545).concat('<b> ... </b>') }}></div>
                                <div className="mt-3"><a data-animation="fadeInUp" data-delay="1.6s" className="main-btn" href={`/page/PROJETS/${contentPage[0].permalink}`}>Lire plus</a></div>
                            </div>
                        }
                        <div className="col-md-5 mt-4">
                            <div class="contact-address mt-30">
                                {service && service.map((p, index) => (
                                    <div className="card mb-4" key={index}>
                                        <div className="d-flex justify-content-between reforme-header bg-secondary">
                                            <div className="p-2 font-weight-bold">{p.title}</div>
                                            {/* <div className="p-2 date-zone bg-warning"><small>&nbsp;</small></div> */}
                                        </div>
                                        <div className="card-body justify-content-center">
                                            <p className="text-justify mb-3" dangerouslySetInnerHTML={{ __html: p.content }}></p>
                                            <div className="d-flex justify-content-between col-xs-8">
                                                <div><a className="download" href={p.fichier ? `${directory}/${p.fichier.filename}` : '#'} target="_blank"><button className="btn btn-outline-success text-uppercase download"><i
                                                    className="fa fa-download"></i> Télécharger</button></a></div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}