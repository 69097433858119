import React, { useEffect, useState } from 'react'
import Ariane from '../components/Common/Ariane';
import ContentPaddsa from '../components/Paddsa/ContentPaddsa';
import {getResource} from '../service/api';
import Loader from '../components/Common/Loader'
import { useParams } from 'react-router';

export default function Paddsa() {

    const {slug} = useParams();
    const[currentpaddsa, setCurrentPaddsa] = useState(null);


    useEffect(() => {

        getResource('pages').then(res => {
            if(res.data.data.length>0){
                setCurrentPaddsa(res.data.data);
            }
            
        })
    }, []);


    return (
        <div>
             <>
        {!currentpaddsa && <Loader/>}
             {currentpaddsa && <ContentPaddsa page={currentpaddsa}/> }
        </>
        </div>
    )
}
