import React, { useEffect, useState } from 'react';
import { getResource } from "../../service/api";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import moment from 'moment'
import Select from 'react-select'
import $ from "jquery";

export default function ContentModuleDocument({ module }) {

    const [projets, setProjets] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [search, setSearch] = useState(null)
    const [searchs, setSearchs] = useState(null)
    const [numberPage, setNumberPage] = useState([])
    const [modal, setModal] = useState(false)
    const [query, setQuery] = useState("");
    const [selection, setSelection] = useState(null);
    const [zone, setZone] = useState('autres')
    const [zchamp, setZchamp] = useState(null)
    const [zones, setZones] = useState([])
    const [zoness, setZoness] = useState([])
    const [structure, setStructure] = useState([])
    const [filiere, setFiliere] = useState([])
    const [recharge, setRecharge] = useState([])
    const [currentProjet, setCurrentProjet] = useState(null)
    const directory_doc = process.env.REACT_APP_UPLOAD_DIRECTORY;

    const STATUS = [
        {value:'0',label:'Non débuté'},
        {value:'-1',label:'En cours'},
        {value:'1',label:'Terminé'},
    ]

    const deps = [
        {value:'alibori',label:'ALIBORI'},
        {value:'atacora',label:'ATACORA'},
        {value:'atlantique',label:'ATLANTIQUE'},
        {value:'borgou',label:'BORGOU'},
        {value:'collines',label:'COLLINES'},
        {value:'couffo',label:'COUFFO'},
        {value:'donga',label:'DONGA'},
        {value:'littoral',label:'LITTORAL'},
        {value:'mono',label:'MONO'},
        {value:'oueme',label:'OUEME'},
        {value:'plateau',label:'PLATEAU'},
        {value:'zou',label:'ZOU'},
    ]

    const pdas = [
        {value:'1',label:'1'},
        {value:'2',label:'2'},
        {value:'3',label:'3'},
        {value:'4',label:'5'},
        {value:'5',label:'5'},
        {value:'6',label:'6'},
        {value:'7',label:'7'},
    ]

    const Champs = [
        {value:'nom',label:'Nom'},
        {value:'financeur',label:'Bailleur'},
        // {value:'description',label:'Description'},
        // {value:'debut',label:'Année de fin'},
        // {value:'fin',label:'Année de début'},
        {value:'departement',label:'Départements'},
        {value:'status',label:'Statut'},
    ]

    var pole

    useEffect(() => {
        getResource(`projets?module_id=${module.id}&page=${currentPage}&public=1`).then(res => {
            setProjets(res.data.data)
            setRecharge(res.data.data)
            let array_pages = Math.ceil(parseInt(res.data.total) / parseInt(res.data.per_page))
            array_pages = Array.from(Array(array_pages).keys())
            setNumberPage(array_pages);
        })
        getResource('structures').then(res => {
            setStructure(res.data.map(d => ({ value: d.structure, label: d.structure })))
        })
        getResource('filieres').then(res => {
            setFiliere(res.data.map(d => ({ value: d.filiere, label: d.filiere })))
        })
    }, [currentPage])

    useEffect(() => {
        if(search != null){
            getResource(`projets?module_id=${module.id}&${zone}=${search}&public=1`).then(res => {
                setProjets(res.data.data)
                let array_pages = Math.ceil(parseInt(res.data.total) / parseInt(res.data.per_page))
                array_pages = Array.from(Array(array_pages).keys())
                setNumberPage(array_pages);
            })
        }
    }, [search])

    useEffect(() => {
        if(searchs != null){
            getResource(`projets?module_id=${module.id}&${selection}=${searchs}&public=1`).then(res => {
                setProjets(res.data.data)
                let array_pages = Math.ceil(parseInt(res.data.total) / parseInt(res.data.per_page))
                array_pages = Array.from(Array(array_pages).keys())
                setNumberPage(array_pages);
            })
        }
    }, [searchs])

    useEffect(() => {
        setZones(null)
        setSearch(null)
        setSelection(null)
        if (zone == 'pole_developpement') setZones(pdas)
        if (zone == 'autres') setZones(Champs)
        if (zone == 'structure') setZones(structure)
        if (zone == 'filière') setZones(filiere)
        setProjets(recharge)
    }, [zone])

    useEffect(() => {
        setZoness(null)
        setZchamp(null)
        if (selection == 'status') setZoness(STATUS); setZchamp('select')
        // if (selection == 'debut') setZones(STATUS); setZchamp('select')
        // if (selection == 'fin') setZones(STATUS); setZchamp('select')
        if (selection == 'departement') setZoness(deps); setZchamp('select')
        if (selection == 'nom')  setZchamp('text')
        if (selection == 'financeur')  setZchamp('text')
        // if (selection == 'description')  setZchamp('text')
        setProjets(recharge)
    }, [selection])

    const getQuery = (e) => {
        setQuery(e.target.value);
    }

    const HandleSubmit = (e) => {
        e.preventDefault();
        if(query && selection) {
            getResource(`projets?module_id=${module.id}&${selection}=${query}`).then(res => {
                setProjets(res.data.data)
                let array_pages = Math.ceil(parseInt(res.data.total) / parseInt(res.data.per_page))
                array_pages = Array.from(Array(array_pages).keys())
                setNumberPage(array_pages);
            })
        }else{
            alert('Veillez remplir tout les champs.\n Merci!')
        }
    }

    const toggle = (current = null) => {
        setModal(!modal)
        setCurrentProjet(current)
    }

    return (
        <div id="simple-page" class="pt-30 pb-30 bg-white">
            <div class="container">
                <div class="row">
                    <div class="col-md-8 offset-md-2">
                        <div >
                            <h6 className=" mt-5  criteria mb-3  ">Rechercher un projet</h6>
                            <div className="btn-group special " role="group" aria-label="Indicateurs clés">
                                <button type="button" className={`btn ${zone == 'structure' && 'btn-success'}`} onClick={() => setZone('structure')}>Structures</button>
                                <button type="button" className={`btn ${zone == 'pole_developpement' && 'btn-success'}`} onClick={() => setZone('pole_developpement')}>Pôles de développement</button>
                                <button type="button" className={`btn ${zone == 'filière' && 'btn-success'}`} onClick={() => setZone('filière')}>Filères</button>
                                <button type="button" className={`btn ${zone == 'autres' && 'btn-warning'}`} onClick={() => setZone('autres')} >Autres</button>
                            </div>
                            {zones && zone != 'autres' && <div className="zone_area mb-3">
                                <Select id="zones" options={zones} placeholder="Valeurs" onChange={(search) => setSearch([search.value])} />
                            </div>}

                            {Champs && zone == 'autres' && <div className="zone_area mb-3">
                                <Select options={Champs} placeholder="Options" onChange={(selection) => setSelection([selection.value])} />
                                { zchamp == 'text' && <div className="input-group mb-3 mt-3">
                                    <input onChange={getQuery}  type="text" className="form-control newsletter-input" placeholder="Valeur" aria-label="Recipient's username" aria-describedby="basic-addon2"/>
                                    <div className="input-group-append" >
                                        <a type="" onClick={HandleSubmit} className="input-group-text site-secondary-bgcolor" id="basic-addon2"><i className="fa fa-search"></i></a>
                                    </div>
                                </div>}
                                { zchamp == 'number' && <div className="input-group mb-3 mt-3">
                                    <input onChange={getQuery}  type="number" className="form-control newsletter-input" placeholder="Valeur" aria-label="Recipient's username" aria-describedby="basic-addon2"/>
                                    <div className="input-group-append" >
                                        <a type="" onClick={HandleSubmit} className="input-group-text site-secondary-bgcolor" id="basic-addon2"><i className="fa fa-search"></i></a>
                                    </div>
                                </div>}
                                {selection != null && zoness && zchamp == 'select' && <div className="zone_area mb-3">
                                    <Select id="zoness" options={zoness}  placeholder="Valeurs" onChange={(searchs) => setSearchs([searchs.value])} />
                                </div>}
                            </div>}
                        </div>
                    </div>
                </div>
                <div class="row mt-4 border-bottom">
                    {projets.map((pro, index) => (
                        <div class="col-lg-4 col-sm-12" key={index}>
                            <a onClick={() => toggle(pro)} className="cursor w-100">
                                <div class="fin-card" style={{ '--color-code': '#44cf43' }} >
                                    <div class="fin-dot-div">
                                        <span class="fin-dot">
                                            <i class="fa fa-tractor"></i>
                                            <i class="si icon-frame-1"></i>
                                        </span>
                                    </div>
                                    <div class="fin-card-body">
                                        <small class="fin-card-text"
                                            title={pro.nom}>
                                            {pro.nom.substring(0, 100)}</small>
                                    </div>
                                </div>
                            </a>

                        </div>
                    ))}
                </div>
            </div>

            <div className="container">
                <div className="col-lg-12">
                    <nav className="courses-pagination mt-50 pb-3">
                        <ul className="pagination justify-content-center">
                            <li className="page-item cursor">
                                <a onClick={() => currentPage > 1 && setCurrentPage(currentPage - 1)} aria-label="Précédent" >
                                    <i className="fa fa-angle-left"></i>
                                </a>
                            </li>
                            {
                                numberPage.map((n) => (
                                    <li key={`page${n + 1}`} className="page-item cursor"><a className={currentPage == (n + 1) && 'active'} onClick={() => setCurrentPage(n + 1)}>{n + 1}</a></li>
                                ))
                            }
                            <li className="page-item cursor">
                                <a onClick={() => currentPage < numberPage.length && setCurrentPage(currentPage + 1)} aria-label="Suivant">
                                    <i className="fa fa-angle-right"></i>
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
            <Modal isOpen={modal} toggle={toggle} size="lg">
                <ModalHeader toggle={toggle}>{currentProjet && currentProjet.nom}</ModalHeader>
                <ModalBody>
                    { currentProjet &&
                        <div className=" col-md-12">
                            <table className='table table-bordered table-striped'>
                                <thead>
                                    <tr>
                                        <th className='text-center' scope="col" colspan="2">Détails</th>
                                    </tr>
                                </thead>
                                <tbody >
                                    <tr>
                                        <th scope="row">Bailleur</th>
                                        <td>{currentProjet.financeur}</td>
                                    </tr>
                                    {currentProjet.montant > 0 &&<tr>
                                        <th scope="row">Montant</th>
                                        <td>{new Intl.NumberFormat().format(currentProjet.montant) + ' F CFA'}</td>
                                    </tr>}
                                    {currentProjet.departement != "" && <tr>
                                        <th scope="row">Départements</th>
                                        <td style={{wordBreak: "break-all"}}>{currentProjet.departement}</td>
                                    </tr>}
                                    {currentProjet.pole_developpement > 0 &&<tr>
                                        <th scope="row">Pôles de développement</th>
                                        <td>{"PDA "+ currentProjet.pole_developpement.toString().split("")}</td>
                                    </tr>}
                                    <tr>
                                        <th scope="row">Statut</th>
                                        <td>{currentProjet.status  == 0 ? "En cours" : currentProjet.status == 1 ? "Terminé" : "Non débuté"}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Taux d'exécution</th>
                                        <td>{currentProjet.taux_execution+' %'}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Début</th>
                                        <td>{moment(currentProjet.debut).format('DD-MM-YYYY')}</td>
                                    </tr>
                                    { currentProjet.fin != null && <tr>
                                        <th scope="row">Fin</th>
                                        <td>{moment(currentProjet.fin).format('DD-MM-YYYY')}</td>
                                    </tr>}
                                </tbody>
                            </table>
                            <div class="col-md-12">
                                <h5>Description</h5>
                                <span>{currentProjet && currentProjet.description}</span>
                            </div>
                        </div>
                    }
                </ModalBody>
                {currentProjet && currentProjet.fichier &&
                    <ModalFooter>
                        
                        <Button color="info" onClick={toggle}> <a href={`${directory_doc}/${currentProjet.fichier.filename}`} style={{color:"white"}}>Télécharger</a> </Button>
                    
                    </ModalFooter> 
                }{' '}
            </Modal>

        </div>
    )
}
