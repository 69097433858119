import React, { useContext, useEffect, useState } from 'react';
import Publication from './Publication';
import { AppContext } from '../../App';

const directory = process.env.REACT_APP_UPLOAD_DIRECTORY
export default function DirectorWords() {

    const { appState: { defaultdatas } } = useContext(AppContext)
    const [setting, setSetting] = useState(null)

    useEffect(() => {
        if (defaultdatas) {
            setSetting(defaultdatas.setting.data.length > 0 ? defaultdatas.setting.data[0] : null)
        }
    }, [defaultdatas])


    return (

        <section id="about-part" className="animated slideInDown">
            { setting && <div className="container">
                <div className="row">
                    <div className="col-lg-5">
                        <div className="section-title mt-50">
                            <h5>{setting.welcome_title}</h5>
                            <div className="row">
                            </div>
                        </div>
                        <div className="about-cont">
                        { setting && setting.fichier &&<img src={`${directory}/${setting.fichier.filename}`} 
                                className=" about-img img-fluid img-thumbnail rounded  mr-auto d-block" alt="Directeur MAEP" />}
                            <div className="text-align-justify" dangerouslySetInnerHTML={{ __html: setting && setting.welcome_content.slice(0, 320) }}></div>

                           { setting && setting.welcome_content.length > 320 && <a href="/welcome" className="main-btn mt-55">Lire plus</a>}
                        </div>
                    </div>

                    <Publication />

                </div>
            </div>
            }
        </section>

    )
}
