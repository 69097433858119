import React, { useContext, useEffect, useState } from 'react';
import "../../styles/slider.css";
import {Form,Col,Alert} from 'react-bootstrap';
import Select from 'react-select'
import {getResource} from '../../service/api';

export default function BannerSlider() {

        const [query, setQuery] = useState([]);
        const[data, setData] = useState([]);
        const [alert, setAlert] = useState(null);

        const customStyles = {
            option: (provided, state) => ({
              ...provided,
              borderBottom: '1px dotted #28a745',
              color: state.isSelected ? 'White' : '#28a745',
              padding: 20,
              zIndex: 100,
            }),
            
          }          


        useEffect(() => {

            getResource('subjects').then(res => {
                if(res.data.data.length>0){
                    setData(res.data.data);
                }
                
        })

        }, []);

        const onSubmit = (e) => {
            e.preventDefault();
            if(typeof query !== 'undefined' && query.length > 0 ) {
                return  window.location.assign(`/section/home/Contacts/${query}`);
            }else{
                setAlert({ success: false, message: 'Veuillez choisir un sujet d\'alert' });
            }
        }
       
        const getQuery = (e) => {
            setQuery(e.value);

        }

        const options = data.map( (d) => {
            return ( { value: d.title, label: d.title })
        })

    return (
    <div>

    <section id="slider-part-3" className="bg_cover back">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-lg-10">
                    <div className="slider-cont-3 text-center">
                            {
                                alert && <Alert className="offset-3 col-md-6" variant={alert.success ? 'success' : 'danger'} onClose={() => setAlert(null)} dismissible>
                                { alert.message }
                                </Alert>
                            }
                        <h2>Alertez-nous!</h2>
                        <span className="alertText">Merci de nous aider à mieux vous servir</span>
                        <div className="slider-search mt-45">
                            <form onSubmit={onSubmit}>
                                <div className="row no-gutters">
                                    <div className="col-sm-9">
                                        <Select
                                        placeholder="Sélectionner un sujet ..."
                                        options={options}
                                        defaultValue={options[0]}
                                        name="query"
                                        onChange={getQuery}
                                        styles={customStyles}
                                        />
                                    </div>
                                    <div className="col-sm-3">
                                        <button type="submit" className="main-btn">Continuer</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </div>
    )
}
